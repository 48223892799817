import {rgba} from 'polished';

//Colors
const dulux = {
  //https://www.dulux.co.uk/en/dulux-colour-year-2020
  /*----- A Home For Care -----*/
  pinkSkies: '#F0E7E1',
  fossilHunting: '#DFDACC',
  borrowedBlue: '#D0DDE3',
  /*----- A Home For Play -----*/
  whiteCotton: '#EEEFEA',
  copperBlush: '#D2907E',
  basicallyBlack: '#494D52',
  ballerinaDance: '#EACBC8',
  citrusZing: '#DEB95F',
  rocketFire: '#DC543F',
  /*----- A Home For Meaning -----*/
  paleTaupe: '#D3CDC7',
  damsonDusk: '#6F6867',
  cobaltNight: '#343639',
  calmClouds: '#E8E8E5',
  /*----- A Home For Creativity -----*/
  stableGates: '#49433F',
  chocolateFountain: '#7E4D3C',
  arsenic: '#3A424C',
  vividAuburn: '#90291E',
};

export const colors = {
  ...dulux,
  white: '#fff',
  link: '#33cabb',
  beige: 'beige',
  blue: '#007bff',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  red: '#dc3545',
  orange: '#fd7e14',
  yellow: '#ffc107',
  green: '#28a745',
  teal: '#20c997',
  cyan: '#17a2b8',
  gray: '#6c757d',
  grayDark: '#343a40',
  primary: '#007bff',
  secondary: '#6c757d',
  success: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#dc3545',
  light: '#f8f9fa',
  dark: '#343a40',
  sideBar: '#3f4a59',
  sideBarSubMenu: '#455160',
};

//Settings
export const HEADER_BG_COLOR = colors['whiteCotton'];
export const HERO_BG_COLOR = colors['copperBlush'];
export const HERO_COLOR = colors['cobaltNight'];

const breakPoints = {
  breakpointXs: '0',
  breakpointSm: '576px',
  breakpointMd: '768px',
  breakpointLg: '992px',
  breakpointXl: '1200px',
};

const theme = {
  colors,
  breakPoints,
  disabledInput: `
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `,
  focusOutlineBeige: `
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px ${rgba(colors.beige, 0.8)}
    }
  `,
  focusOutlineLink: `
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px ${rgba(colors.link, 0.8)}
    }
  `,
  focusOutlineNavButton: `
    &:focus {
      outline: 0;
      border-bottom-color: ${colors.link};
    }
  `,
  focusOutlineSideBar: `
    &:focus {
      outline: 0;
      border: 1px solid ${colors.link};
    
      &:active {
        border: none;
      }
    }
  `,
  hoverSidebar: `
    &:hover, 
    &:focus {
      color: #fff;
      opacity: 0.75; 
      color: ${colors.link};
    }
  `,
  nav: {
    height: '64px',
  },
};

export default theme;
